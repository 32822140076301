html, body{
  font-size:16px;
  font-family: 'Poppins', sans-serif;
}
*::-webkit-scrollbar{
  height:4px ;
  width:8px;
}

*::-webkit-scrollbar-thumb{
  background: rgba(60,127,221);
}
*::-webkit-scrollbar-track{
  background : #e3e3e3;
}

.nepali-date-picker .calender table td{
  padding: 5px;
}


