@page {
    size: A4;
    margin: 10px;
}
/*// @media all {*/
/*html, body {*/
/*    width: 200mm;*/
/*    height: 210mm;*/
/*    font-size: 16px;*/
/*    line-height: 20px;*/
/*    !*border: 1px dashed #000;*!*/
/*}*/
main{
    width: 210mm;
    height: 210mm;

}
.p-t{
    padding: 5px 0px;
}
.p-l{
    padding-left: 5px;
}
.p-r{
    padding-right: 20px;
}
.contain{
    z-index: 100;
    /*background: rgba(208,53,187,0.55);*/
    margin-top: 20px;
    width: 170mm;
    height: 140mm;
    border: 1px dashed #000;
    display: flex;
    flex-direction: column;
    padding: 10px;
    page-break-after: always;
}
/*.watermark{*/
/*    background-image:url(${apanga});*/
/*    background-repeat:repeat;*/
/*    background-size:20%;*/
/*}*/
.header{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
}
.header-logo{
    width: 100px ;
    margin-right: 10px;
}
.header-detail{
    width: 100%;
    padding-left: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.title{
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    flex: 1;
    width: 70%;
    margin-right: 100px;
}
.bold{
    font-weight: bold;
}

.card-title{
    width: 50%;
    margin:10px auto;
    display: flex;
    justify-content: center;
    background: #333132;
    color: white;
    font-size: 18px;
    line-height: 25px ;
}

.user-image{
    height:40mm;
    width: 35mm;
    border:1px solid black;
    margin-top: -10px;
    margin-right: 4px;
}
img{
    height: inherit;
    width: inherit;
    object-fit: cover;
}
.text-small{
    font-size: 14px;
}
.address-info{
    margin-right: 10px;
}
.card-main{
    display: flex;
    justify-content: space-between;
}
.flex{
    width: 100%;
    display: flex;
    /*align-items: center;*/
}

.left-content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-basis: 100%;
    flex: 1;
}
.lose-case{
    font-size: 12px;
    font-style: italic;
    text-align: center;
}